import { CarouselProps } from "./CarouselProps";
import { CarouselState } from "./CarouselState";
import React, { Component, useReducer, useContext } from 'react';
import { PauseState, PauseContext } from '../../../utils/pauseContext';
import { CarouselHandler } from "./CarouselHandler";
import debounce, { DebouncedFunction } from '../debounce';
import clsx from 'clsx';
import { IconButton, Icon } from '@material-ui/core';
import Animation from '@sightworks/transition';

function hideNaN(...values: number[]): number {
	return values.find(v => !isNaN(v));
}

export class CarouselComponent extends Component<CarouselProps, CarouselState> {
	static contextType = PauseContext;
	context: PauseState;

	state: CarouselState = {
		cycle: false,
		leftIndex: 0,
		itemsVisible: 1,
		disabled: false,
		mode: this.props.mode,
		
		isMobile: false
	}

	wheelSamples: [number, number, number, number, number, number, number, number, number] = [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
	sampleCount: number = 0;
	ranWheel: boolean = false;

	get infinite() {
		return this.props.infinite ?? false;
	}

	get mode() {
		return this.props.mode ?? 'slide';
	}

	get sizeInfinite() {
		return this.props.sizeInfinite ?? this.infinite;
	}

	get reserveButtonSpace() {
		return this.props.reserveButtonSpace ?? false;
	}

	_defaultCarouselHandler: CarouselHandler = (carousel, child, index) => {};

	get carouselItemLeaving() { return this.props.carouselItemLeaving ?? this._defaultCarouselHandler; }
	get carouselItemLeft() { return this.props.carouselItemLeft ?? this._defaultCarouselHandler };
	get carouselItemEntering() { return this.props.carouselItemEntering ?? this._defaultCarouselHandler; }
	get carouselItemEntered() { return this.props.carouselItemEntered ?? this._defaultCarouselHandler; }

	private _lastChildren: React.PropsWithChildren<{}>['children'];
	private _lastChildrenArray: ReturnType<React.ReactChildren['toArray']>

	get children() {
		if (this._lastChildren === this.props.children) return this._lastChildrenArray;
		this._lastChildrenArray = React.Children.toArray(this.props.children);
		this._lastChildren = this.props.children;
		return this._lastChildrenArray;
	}

	private wheelTimeout: ReturnType<typeof setTimeout>;

	wheelTimeoutHandler = () => {
		if (this.state.disabled) return;
		if (this.context.state) return;
		if (!this.ranWheel) {
			this.runWheel();
		}
		this.ranWheel = false;
		this.wheelTimeout = null;
		this.sampleCount = 0;
		this.wheelSamples = [ 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
	}
	
	wheel = (event: WheelEvent) => {
		if (this.state.disabled) return;
		if (this.state.itemsVisible >= this.children.length) return;
		if (this.ranWheel) {
			event.preventDefault();
			return;
		}
		console.log(event.deltaMode, event.deltaX, event.deltaY, event.deltaZ);
		this.sampleCount++;
		clearTimeout(this.wheelTimeout);
		this.wheelTimeout = setTimeout(this.wheelTimeoutHandler, 64);
		var offset = 1;
		if (event.deltaMode == 1) offset = 16;
		if (event.deltaMode == 2) offset = window.innerHeight;
		
		var delta = event.deltaX * offset;
		this.wheelSamples.shift();
		this.wheelSamples.push(delta);
		
		var dx = 0;
		if (this.wheelSamples.filter(v => Math.abs(this.wheelSamples[4]) - Math.abs(v) > 0).length == 8) {
			dx = this.wheelSamples[4];
		}
		if (dx) {
			this.runWheel();
			this.ranWheel = true;
			event.preventDefault();
		}
	}
	
	runWheel() {
		if (this.state.disabled) return;
		if (this.context.state) return;
		var direction = this.wheelSamples[4] > 0 ? 1 : (this.wheelSamples[4] < 0 ? -1 : 0);
		if (direction == 0)	return;
		if (direction < 1) this.cyclePrev(true);
		else this.cycleNext(true);
	}

	logTouchEvent(event: TouchEvent) {
		console.log(`${event.type}: ${this.mapTouches(event.touches)} | ${this.mapTouches(event.changedTouches)}`)
	}
	
	mapTouches(touchList: TouchList) {
		let items: Touch[] = [].slice.call(touchList);
		return items.map(touch => `${touch.identifier} @ ${touch.clientX}x${touch.clientY}`).join(', ');
	}

	private inTouch: boolean = false;
	private rejectTouch: boolean = false;
	private touch: {
		ID: Touch['identifier'],
		clientX: Touch['clientX'],
		clientY: Touch['clientY']
	} = null;
	private lastTouch: {
		ID: Touch['identifier'],
		clientX: Touch['clientX'],
		clientY: Touch['clientY']
	} = null;
	private stopped: boolean;

	touchStart = (event: TouchEvent) => {
		if (this.state.disabled) return;
		this.logTouchEvent(event);
		if (this.inTouch) {
			this.rejectTouch = true;
			event.preventDefault();
			return;
		}
		this.inTouch = true;
		this.rejectTouch = false;
		this.touch = { ID: event.touches[0].identifier, clientX: event.touches[0].clientX, clientY: event.touches[0].clientY };
		this.lastTouch = this.touch;
		this.stopped = false;
		// event.preventDefault();
		return;
	}

	touchMove = (event: TouchEvent) => {
		if (this.state.disabled) return;
		if (this.context.state) return;
		if (this.stopped) event.preventDefault();
		this.logTouchEvent(event);
		if (this.inTouch && !this.rejectTouch) {
			this.lastTouch = {
				ID: event.touches[0].identifier,
				clientX: event.touches[0].clientX,
				clientY: event.touches[0].clientY
			}
			if (Math.abs(event.touches[0].clientX - this.touch.clientX) > 30) {
				console.log("touchMove(): trying to stop movement");
				this.stopped = true;
				event.preventDefault();
			}
		}
	}

	touchEnd = (event: TouchEvent) => {
		if (this.state.disabled) return;
		if (this.stopped) event.preventDefault();
		
		this.logTouchEvent(event);
		if (this.inTouch && !this.rejectTouch && this.touch && this.lastTouch) {
			var delta, size;
			delta = this.lastTouch.clientX - this.touch.clientX;
			size = 340;
			if (Math.abs(delta) > (0.1 * size)) {
				delta *= -1;
				if (delta < 0) this.cyclePrev(true);
				else this.cycleNext(true);
			}
		}
		this.inTouch = false;
		this.rejectTouch = false;
		this.touch = null;
		this.lastTouch = null;
	}
	
	private mediaQuery: MediaQueryList;
	private mobileMediaQuery: MediaQueryList;

	componentDidMount() {
		if (this.props.media) {
			this.mediaQuery = window.matchMedia(this.props.media);
			this.mediaQuery.addEventListener('change', this.mediaQueryListener);
			this.mediaQueryListener();
		}
		
		this.mobileMediaQuery = window.matchMedia(`(max-width: 767px)`);
		this.mobileMediaQuery.addEventListener('change', this.mobileMediaQueryListener);
		this.mobileMediaQueryListener();
	}
	
	mobileMediaQueryListener = () => {
		// Enable when the media query specified by props.media matches
		var v = this.mobileMediaQuery.matches;
		if (v != this.state.isMobile) {
			this.setState({ isMobile: v })
		}
	}

	mediaQueryListener = () => {
		// Enable when the media query specified by props.media matches
		var v = !this.mediaQuery.matches;
		if (v != this.state.disabled) {
			this.setState({ disabled: v });
		}
	}

	componentWillUnmount() {
		this.setContentNode(null);
		if (this.mediaQuery) {
			this.mediaQuery.removeEventListener('change', this.mediaQueryListener);
			delete this.mediaQuery;
		}
		if (this.mobileMediaQuery) {
			this.mobileMediaQuery.removeEventListener('change', this.mobileMediaQueryListener);
			delete this.mobileMediaQuery;
		}
	}

	private contentNode: HTMLElement;
	private timer: ReturnType<typeof setTimeout>;
	private _frame: ReturnType<typeof requestAnimationFrame>;

	setContentNode = (node: HTMLElement) => {
		if (this.contentNode) {
			this.contentNode.removeEventListener('wheel', this.wheel, { passive: false });
			this.contentNode.removeEventListener('touchstart', this.touchStart, { passive: false });
			this.contentNode.removeEventListener('touchmove', this.touchMove, { passive: false });
			this.contentNode.removeEventListener('touchend', this.touchEnd, { passive: false });
		}
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.contentNode = node;
		if (node) {
			window.addEventListener('resize', this.resized);
			if (!this.props.noControls) {
				node.addEventListener('wheel', this.wheel, { passive: false });
				node.addEventListener('touchstart', this.touchStart, { passive: false });
				node.addEventListener('touchmove', this.touchMove, { passive: false });
				node.addEventListener('touchend', this.touchEnd, { passive: false });
			}
			if (this.props.period) {
				this.timer = setTimeout(() => this.cycleNext(), this.props.period);
			}
			this._frame = requestAnimationFrame(() => this.resized(true));
		} else {
			window.removeEventListener('resize', this.resized)
		}
	}
	
	private _prevButton: HTMLElement;
	gotPrevButton = (button: HTMLElement) => {
		this._prevButton = button;
		if (!this._frame) this._frame = requestAnimationFrame(() => this.resized(true));
	}
	
	private _nextButton: HTMLElement;
	gotNextButton = (button: HTMLElement) => {
		this._nextButton = button;
		if (!this._frame) this._frame = requestAnimationFrame(() => this.resized(true));
	}

	private _classToSelector(s: string): string {
		return s.split(/\s+/).map(v => `.${v}`).join('');
	}

	private _emptyCarousel: boolean;
	private _debouncedResized: DebouncedFunction;

	resized = (_value?: Event | boolean) => {
		if (_value === true) this._emptyCarousel = true;
		if (!this._debouncedResized) {
			 this._debouncedResized = debounce(() => {
				 this.resized_inner(this._emptyCarousel)
				 this._emptyCarousel = false;
			 });
		}
		this._debouncedResized();
	}

	resized_inner = (_value: boolean): void => {
		this._frame = null;
		if (!this.contentNode) return;
		if (this.state.disabled) return;
		if (this.context.state) return;
		var w = this.contentNode.parentElement.offsetWidth;
		var btn = this.contentNode.parentNode.parentNode.querySelector<HTMLElement>(this._classToSelector(this.props.classes.carouselCyclePrev));
		btn.style.display = 'unset';
		let bws;
		if (!this.props.centered) {
			bws = btn.style.width;
			btn.style.width = ''
		}
		var bw = this.props.outside ? 0 : (2 * (btn?.offsetWidth ?? 0));
		if (bws) btn.style.width = bws;
		btn.style.display = '';

		console.log(`Carousel.resized(): container width = ${w}px, total button width = ${bw}`);

		if (this.reserveButtonSpace) w -= bw;
		
		Object.assign(this.contentNode.style, { 
			paddingLeft: 0, 
			width: '',
			marginLeft: 0,
			marginRight: 0
		});

		var itemWidth = this.contentNode.querySelector<HTMLElement>(this._classToSelector(this.props.classes.carouselItem) + ':first-child').offsetWidth;
		var maxWidth = itemWidth * this.children.length;
		
		var w1 = Math.floor((w + 1) / itemWidth) * itemWidth;
		w1 = Math.min(maxWidth, w1);
		w1 = Math.max(w1, itemWidth);
		
		if (true || !this.sizeInfinite) {
			w = w1;
		}

		let buttonWidth = !this.props.centered ? `${bw / 2}px` : `calc((100% - ${w}px) / 2)`;
		Object.assign(this.contentNode.style, {
			width: '100%',
			paddingLeft: buttonWidth,
			marginLeft: 0,
			marginRight: 0
		});

		if (this._prevButton) {
			if (this.props.outside) {
				this._prevButton.style.width = '';
				this._prevButton.style.transform = 'translateX(-100%)'
			} else {
				Object.assign(this._prevButton.style, { width: buttonWidth, transform: '' });
			}
		}
		if (this._nextButton) {
			if (this.props.outside) {
				this._nextButton.style.width = '';
				this._nextButton.style.transform = 'translateX(100%)'
			} else {
				Object.assign(this._nextButton.style, { width: buttonWidth, transform: '' });
			}
		}
		var prevItemsVisible = this.state.itemsVisible;
		var newItemsVisible = w1 / itemWidth;

		if (_value === true) {
			prevItemsVisible = -1;
		}

		if (newItemsVisible > prevItemsVisible) {
			for (var i = prevItemsVisible + 1; i < newItemsVisible; i++) {
				var p = this.state.leftIndex + i;
				if (p >= this.children.length) p -= this.children.length;
				this.carouselItemEntering(this, this.children[p], p);
			}
		} else if (newItemsVisible < prevItemsVisible) {
			for (var i = prevItemsVisible; i > newItemsVisible; i--) {
				var p = this.state.leftIndex + i;
				if (p >= this.children.length) p -= this.children.length;
				this.carouselItemLeaving(this, this.children[p], p);
			}
		}
		
		this.setState({ itemsVisible: hideNaN(w1 / itemWidth, 1) }, () => {
			if (newItemsVisible > prevItemsVisible) {
				for (var i = prevItemsVisible + 1; i < newItemsVisible; i++) {
					var p = this.state.leftIndex + i;
					if (p >= this.children.length) p -= this.children.length;
					this.carouselItemEntered(this, this.children[p], p);
				}
			} else if (newItemsVisible < prevItemsVisible) {
				for (var i = prevItemsVisible; i > newItemsVisible; i--) {
					var p = this.state.leftIndex + i;
					if (p >= this.children.length) p -= this.children.length;
					this.carouselItemLeft(this, this.children[p], p);
				}
			}
		});
	}
	
	items() {
		if (this.mode == 'slide') {
			var end = this.children.slice(this.state.leftIndex);
			// if (this.infinite) {
			 	end = end.concat(this.children.slice(0, this.state.leftIndex));
			// } else 
			// if (end.length < this.state.itemsVisible && end.length < this.children.length) {
			// 	end = this.children.slice(this.children.length - this.state.itemsVisible);
			// }
			return end;
		}
		return this.children;
	}

	rootNode: HTMLElement;
	gotRoot = (node: HTMLElement) => {
		this.rootNode = node;
	}
	
	render() {
		if (this.state.disabled) {
			return (
				<div className={clsx(this.props.classes.root, this.props.classes.disabledCarouselContainer)}>
					<div className={clsx(this.props.classes.carouselContent)} style={{ width: "" }}>
						{this.children.map((carouselItem, index) => (
							<div className='carousel-item' key={index} ref={node => this.setupDisabledCarouselItem(node)}>{carouselItem}</div>
						))}
					</div>
				</div>
			);
		}
		var isActive = (node: this['children'][number]) => {
			if (this.mode == 'slide') {
				return this.items().slice(0, this.state.itemsVisible).indexOf(node) != -1;
			}
			return this.state.leftIndex == this.items().indexOf(node);
		}

		return (
			<div className={clsx(this.props.classes.root, this.props.classes.carouselContainer, {
				[this.props.classes.carouselWithoutControls]: this.props.noControls,
				[this.props.classes.carouselWithIndicators]: this.props.indicators
			})} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} ref={this.gotRoot}>
				{!this.props.noControls ? (
					<div className={clsx(this.props.classes.carouselCycle, this.props.classes.carouselCyclePrev)} ref={this.gotPrevButton}>
						{this.state.itemsVisible < this.children.length ? (
							<IconButton disabled={!this.infinite && this.state.leftIndex <= 0}
								className={clsx(this.props.classes.iconButton, this.props.classes.left, {
									[this.props.classes.outside]: this.props.outside,
									[this.props.classes.outline]: this.props.outline
								})} onClick={() => this.cyclePrev(true)}
								title="Previous item">
								<Icon>keyboard_arrow_left</Icon>
							</IconButton>
						) : null}
					</div>
				) : null}
				
				{this.props.indicators ? (
					<div className={this.props.classes.carouselIndicatorBox}>
						{this.children.map((carouselItem, index) => (
							<div key={index}
								style={{ transition: `${this.props.speed || '300'}ms ease` }}
								data-index={index}
								onClick={this._doCycleToItem}
								className={clsx(this.props.classes.carouselIndicatorItem, {[this.props.classes.active]: isActive(carouselItem)})}
							/>
						))}
					</div>
				) : null}
				
				<div className={clsx(this.props.classes.carouselContent, {
					[this.props.classes.notInfinite]: !this.props.infinite,
					[this.props.classes.transitionFade]: this.state.mode == 'fade',
					[this.props.classes.transitionSlide]: this.state.mode == 'slide',
					[this.props.classes.carouselContentCentered]: this.props.centered && this.state.itemsVisible >= this.children.length,
					[this.props.classes.carouselContentLeft]: !this.props.centered && this.state.itemsVisible >= this.children.length
				})} ref={this.setContentNode}>
					{(this.mode == 'slide' && this.state.itemsVisible < this.children.length) ? (
						<div className={clsx(this.props.classes.carouselItem, this.props.classes.previousSpace, {
							[this.props.classes.placeholderPreviousSpace]: !this.state.leftIndex && !this.infinite
						 })} key="previous" ref={node => this.setupCarouselItem(node, null, "previous")}>
							{this._makeInertClone(this.items()[this.items().length - 1])}
						</div>
					) : null}
					{this.items().map((carouselItem, index) => (
						<div className={clsx(this.props.classes.carouselItem, {
							[this.props.classes.placeholderNextSpace]: (!this.infinite && this.children.indexOf(carouselItem) < this.state.leftIndex)
						 })} key={index} ref={node => this.setupCarouselItem(node, carouselItem)}>
							{carouselItem}
						</div>
					))}
					{ /* We don't actually need a 'next-space' */ null }
				</div>

				{!this.props.noControls ? (
					<div className={clsx(this.props.classes.carouselCycle, this.props.classes.carouselCycleNext)} ref={this.gotNextButton}>
						{this.state.itemsVisible < this.children.length ? (
							<IconButton disabled={!this.infinite && (this.state.itemsVisible + this.state.leftIndex) >= this.children.length}
								className={clsx(this.props.classes.iconButton, this.props.classes.left, {
									[this.props.classes.outside]: this.props.outside,
									[this.props.classes.outline]: this.props.outline
								})} onClick={() => this.cycleNext(true)} 
								title="Next item">
								<Icon>keyboard_arrow_right</Icon>
							</IconButton>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
	
	private _makeInertClone(el: React.ReactChild | React.ReactPortal | React.ReactFragment): React.ReactChild | React.ReactPortal | React.ReactFragment {
		if (el && typeof el == 'object' && 'props' in el) {
			if (typeof el.type == 'function' || (typeof el.type == 'object' && el.type && (el.type as any).$$typeof === Symbol.for('react.forward_ref'))) {
				let props: any = { ...el.props };
				props.asInertCopy = true;
				props.key = el.key;
				return React.createElement(el.type, props);
			}
		}
		return el;
	}
	setupCarouselItem(node: HTMLElement, carouselNode: React.ReactChild | React.ReactPortal | React.ReactFragment, asPreviousItem?: 'previous') {
		if (!carouselNode && asPreviousItem == 'previous') {
			if (this.infinite) {
				var t = this.items();
				carouselNode = t[t.length - 1];
			} else {
				var t = this.children.slice(this.state.leftIndex).concat(this.children.slice(0, this.state.leftIndex));
				carouselNode = t[t.length - 1];
			}
		}

		if (node) {
			if (this.state.mode == 'slide') {
				if (this.props.noTransform)
					Object.assign(node.style, { left: '' });
				else
					node.style.transform = '';
			} else {
				var t = this.items();
				var pos = t.indexOf(carouselNode);
				if (pos == this.state.leftIndex) {
					node.style.zIndex = '2';
					node.style.opacity = '1';
				} else {
					node.style.zIndex = node.style.opacity = '0';
				}
			}
		}
	}

	setupDisabledCarouselItem(node: HTMLElement) {
		if (node) {
			if (this.props.noTransform)
				Object.assign(node.style, { left: '' });
			else
				node.style.transform = '';			
		}
	}

	private animation: Animation;
	
	cyclePrev(userTriggered = false) {
		if (this.animation)	return;
		if (this.state.disabled) return;
		if (this.context.state) return;
		if (!this.infinite && this.state.leftIndex <= 0) return;
		
		if (this.state.itemsVisible >= this.children.length || (!userTriggered && !this.isInViewport())) {
			if (this.props.period) this.timer = setTimeout(() => this.cycleNext(), this.props.period);
			return;
		}
		
		var positionCallback: (position: number) => void;
		var finishedCallback: (finished: boolean) => void;

		finishedCallback = finished => {
			if (!finished) positionCallback(1);
			delete this.animation;

			var itemLeaving = this.state.leftIndex + this.state.itemsVisible - 1;
			var itemEntering = this.state.leftIndex - 1;
			if (itemLeaving >= this.children.length) itemLeaving -= this.children.length;
			if (itemEntering < 0) itemEntering += this.children.length;
			this.carouselItemLeft(this, this.children[itemLeaving], itemLeaving);
			this.carouselItemEntered(this, this.children[itemEntering], itemEntering);

			this.setState(state => {
				var lp = state.leftIndex - 1;
				if (this.infinite) {
					if (lp == -1) lp = this.children.length - 1;
				} else {
					if (lp < 0) lp = 0;
				}
				return { leftIndex: lp };
			})
			clearTimeout(this.timer);

			if (this.props.period) this.timer = setTimeout(() => this.cycleNext(), this.props.period);
		};
		
		switch (this.mode) {
			case 'slide':
				positionCallback = position => {
					var pp = -100 + (100 * position);
					console.log(pp);
					var mode: (node: HTMLElement) => void = this.props.noTransform ? node => Object.assign(node.style, { left: `${pp}%` }) : node => node.style.transform = `translateX(${pp}%)`;
					if (this.contentNode) this.contentNode.querySelectorAll(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`).forEach(mode);
				};
				break;
			case 'fade':
				positionCallback = position => {
					var cq: HTMLElement[] = this.contentNode ? [].slice.call(this.contentNode.querySelectorAll<HTMLElement>(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`)) : [];
					var c1 = cq[this.state.leftIndex - 1] || cq[cq.length - 1]; // target
					var c2 = cq[this.state.leftIndex]; // current
					var cr = cq.filter(node => node != c1 && node != c2);
					if (c1) {
						c1.style.opacity = String(position);
						c1.style.zIndex = '2';
					}
					if (c2) {
						c2.style.opacity = String(1 - position);
						c2.style.zIndex = '1';
					}
					cr.forEach(node => {
						node.style.opacity = '0'
						node.style.zIndex = '0';
					});
				}
				break;
		}

		this.animation = new Animation(userTriggered ? 300 : (this.props.speed || 300), 'ease', positionCallback, finishedCallback);
		requestAnimationFrame(() => {
			var itemLeaving = this.state.leftIndex + this.state.itemsVisible - 1;
			var itemEntering = this.state.leftIndex - 1;
			if (itemLeaving >= this.children.length) itemLeaving -= this.children.length;
			if (itemEntering < 0) itemEntering += this.children.length;
			this.carouselItemLeaving(this, this.children[itemLeaving], itemLeaving);
			this.carouselItemEntering(this, this.children[itemEntering], itemEntering);
			try { this.contentNode.dataset.action = 'cyclePrev'; } catch (e) { ; }
			this.animation.start();
		});
	}

	_doCycleToItem = (event: React.MouseEvent<HTMLElement>) => {
		this.cycleToItem(Number(event.currentTarget.dataset.index));
	}

	cycleToItem(index: number) {
		if (this.animation) return;
		if (this.context.state) return;
		if (this.state.disabled) return;
		if (this.state.itemsVisible >= this.children.length) return;
		
		// If an item is clocked on, it always behaves as though cycleNext() happened, only the next item is the item selected.
		if (index == this.state.leftIndex) {
			return; // The current node.
		}

		var positionCallback = (position: number) => {
			if (!this.contentNode) return;
			var cq = this.contentNode.querySelectorAll<HTMLElement>(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`);
			var c1: HTMLElement, c2: HTMLElement;
			if (this.mode == 'slide') {
				c2 = cq[1]; // Current item
				if (index < this.state.leftIndex) {
					var np = this.state.leftIndex - index;
					c1 = cq[cq.length - np];
				} else {
					var np = index - this.state.leftIndex;
					c1 = cq[np + 1];
				}
			} else {
				c1 = cq[index];
				c2 = cq[this.state.leftIndex]; // current
			}
			var cr: HTMLElement[] = [].filter.call(cq, (node: HTMLElement) => node != c1 && node != c2);
			if (c1) {
				c1.style.opacity = String(position);
				c1.style.zIndex = '2';
			}
			if (c2) {
				c2.style.opacity = String(1 - position);
				c2.style.zIndex = '1';
			}
			cr.forEach(node => {
				node.style.zIndex = node.style.opacity = '0'
			});
		}

		var finishedCallback = (finished: boolean) => {
			if (!finished) positionCallback(1);
			delete this.animation;

			// This only really works for a single-item carousel.
			var itemEntering = index;
			var itemLeaving = this.state.leftIndex;
			this.carouselItemLeft(this, this.children[this.state.leftIndex], this.state.leftIndex);
			this.carouselItemEntered(this, this.children[itemEntering], itemEntering);

			this.setState(state => {
				var lp = index;
				if ((lp - 1 + state.itemsVisible) >= this.children.length) {
					lp = this.children.length - state.itemsVisible;
				}
				return { leftIndex: lp, mode: this.mode };
			}, () => {
				if (this.mode == 'slide') {
					if (!this.contentNode) return;
					var cq = this.contentNode.querySelectorAll<HTMLElement>(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`);
					cq.forEach(node => {
						node.style.zIndex = node.style.opacity = '';
					});
				}
			})
			clearTimeout(this.timer);
			if (this.props.period) this.timer = setTimeout(() => this.cycleNext(), this.props.period);
		}
		if (this.props.period)
			clearTimeout(this.timer);

		this.animation = new Animation(300, 'ease', positionCallback, finishedCallback);
		
		this.setState({ mode: 'fade' }, () => {
			var itemEntering = index;
			var itemLeaving = this.state.leftIndex;
			this.carouselItemLeaving(this, this.children[this.state.leftIndex], this.state.leftIndex);
			this.carouselItemEntering(this, this.children[itemEntering], itemEntering);
			requestAnimationFrame(() => {
				try { this.contentNode.dataset.action = 'cycleToSpecific'; } catch (e) { ; }
				this.animation.start();
			});
		});
	}

	cycleNext(userTriggered = false) {
		if (this.animation) return;
		if (this.state.disabled) return;
		// if (this.context.state) return;
		if (!this.infinite && (this.state.leftIndex + this.state.itemsVisible) >= this.children.length) return;
		if (this.state.itemsVisible >= this.children.length || (!userTriggered && !this.isInViewport()) || this.context.state) {
			if (this.props.period) this.timer = setTimeout(() => this.cycleNext(), this.props.period);
			return;
		}
		var pl: number[] = [], pv = -100;
		var positionCallback: (position: number) => void;
		switch (this.mode) {
			case 'slide':
				positionCallback = position => {
					var pp = -100 - (100 * position);
					pl.push(pv - pp);
					pv = pp;
					// console.log(pp);
					var mode: (node: HTMLElement) => void = this.props.noTransform ? node => Object.assign(node.style, { left: `${pp}%` }) : node => node.style.transform = `translateX(${pp}%)`;
					(this.contentNode ? this.contentNode.querySelectorAll(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`) : []).forEach(mode);
				};
				break;
			case 'fade':
				positionCallback = position => {
					if (!this.contentNode) return;
					var cq = this.contentNode.querySelectorAll<HTMLElement>(`:scope > ${this._classToSelector(this.props.classes.carouselItem)}`);
					var c1 = cq[this.state.leftIndex + 1] || cq[0]; // target
					var c2 = cq[this.state.leftIndex]; // current
					var cr: HTMLElement[] = [].filter.call(cq, (node: HTMLElement) => node != c1 && node != c2);
					if (c1) {
						c1.style.opacity = String(position);
						c1.style.zIndex = '2';
					}
					if (c2) {
						c2.style.opacity = String(1 - position);
						c2.style.zIndex = '1';
					}
					cr.forEach(node => {
						node.style.zIndex = node.style.opacity = '0'
					});
				}
				break;
		}
		
		var finishedCallback = (finished: boolean) => {
			if (!finished) positionCallback(1);
			delete this.animation;
			console.log(pl);
			
			var itemEntering = this.state.leftIndex + this.state.itemsVisible;
			if (this.children.length <= itemEntering) itemEntering -= this.children.length;
			this.carouselItemLeft(this, this.children[this.state.leftIndex], this.state.leftIndex);
			this.carouselItemEntered(this, this.children[itemEntering], itemEntering);

			this.setState(state => {
				var lp = state.leftIndex + 1;
				if (this.infinite) {
					if (lp == this.children.length) lp = 0;
				} else {
					if ((state.leftIndex + state.itemsVisible) >= this.children.length) {
						lp = this.children.length - state.itemsVisible;
					}
				}
				return { leftIndex: lp };
			})
			clearTimeout(this.timer);
			if (this.props.period) this.timer = setTimeout(() => this.cycleNext(), this.props.period);
		}
		this.animation = new Animation(userTriggered ? 300 : (this.props.speed || 300), 'ease', positionCallback, finishedCallback);
		requestAnimationFrame(() => {
			var itemEntering = this.state.leftIndex + this.state.itemsVisible;
			if (this.children.length <= itemEntering) itemEntering -= this.children.length;
			
			this.carouselItemLeaving(this, this.children[this.state.leftIndex], this.state.leftIndex);
			this.carouselItemEntering(this, this.children[itemEntering], itemEntering);
			try { this.contentNode.dataset.action = 'cycleNext'; } catch (e) { ; }
			this.animation.start();
		});
	}

	componentDidUpdate(prevProps: CarouselProps, prevState: CarouselState) {
		if (prevState.isMobile != this.state.isMobile) {
			if (this.props.children) {
				var itemPosition = this.children.map((v, i) => typeof v == 'object' && 'props' in v && v.props.isCurrentObject ? i : null).filter(v => v !== null).shift();
				if (itemPosition !== void 0) {
					this.setState({ leftIndex: this.state.isMobile ? itemPosition : 0 });
					return;
				}
			}
		}
		if (prevState.leftIndex != this.state.leftIndex) {
			try { this.contentNode.dataset.action = null; } catch (e) { ; }
		}

		if (prevState.itemsVisible != this.state.itemsVisible) {
			this.resized();
		}
	}

	isInViewport() {
		if (!this.rootNode) return false;
		var r = this.rootNode.getBoundingClientRect();
		if (r.top < 0 || r.bottom > document.documentElement.clientHeight) return false;
		return true;
	}
}
