import { createContext } from 'react';

export type VerticalTimelineContextType = {
    classes: Record<
        'verticalTimeline' | 
        'verticalTimelineElement' |
        'verticalTimelineDate' |
        'verticalTimelineIcon' |
        'verticalTimelineText', string>;
}

export const VerticalTimelineContext = createContext<VerticalTimelineContextType>({
    classes: {
        verticalTimeline: 'vertical-timeline',
        verticalTimelineElement: 'vertical-timeline-element',
        verticalTimelineDate: 'vertical-timeline-date',
        verticalTimelineIcon: 'vertical-timeline-icon',
        verticalTimelineText: 'vertical-timeline-text'
    }
})