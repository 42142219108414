import React, { useState, useEffect, useCallback, useContext } from 'react';
import { StyleLoader, ThemeLoader, CustomBackgroundContext } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill
import VerticalTimelineElementProps from './props';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { VerticalTimelineContext } from '../vertical-timeline-context/context';
import Icon from '@material-ui/core/Icon';

const VerticalTimelineBlock = ({
	classes: localClasses,
	content,
	date,
	icon
}: VerticalTimelineElementProps, ref: React.Ref<any>) => {
	const { classes } = useContext(VerticalTimelineContext);
	return (
		<VerticalTimelineElement className={clsx(classes.verticalTimelineElement, localClasses.root)}
			dateClassName={clsx(classes.verticalTimelineDate, localClasses.date)}
			iconClassName={clsx(classes.verticalTimelineIcon, localClasses.icon, 'material-icons')}
			textClassName={clsx(classes.verticalTimelineText, localClasses.text)}
			date={date}
			icon={icon ? <Icon>{icon}</Icon> : null}
		>
			{getChildren(content)}
		</VerticalTimelineElement>
	)
}

export default ThemeLoader(
	StyleLoader(
		VerticalTimelineBlock,
		makeStyles(
			theme => ({
				root: {
				},
				date: {},
				icon: {},
				text: {}
			}),
			{ name: 'VerticalTimelineElement' }
		),
		true
	)
);

