import PropTypes from 'prop-types'
import React from 'react'
import addClass from 'dom-helpers/addClass'
import removeClass from 'dom-helpers/removeClass'
import getWidth from 'dom-helpers/width'
import scrollbarSize from 'dom-helpers/scrollbarSize'
import { Context } from './Styles';
import clsx from 'clsx';

import * as dates from './utils/dates'
import { navigate } from './utils/constants'
import { inRange } from './utils/eventLevels'
import { isSelected } from './utils/selection'

class Agenda extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
    this.dateColRef = React.createRef()
    // this.timeColRef = React.createRef()
    this.contentRef = React.createRef()
    this.tbodyRef = React.createRef()
  }

  componentDidMount() {
    this._adjustHeader()
  }

  componentDidUpdate() {
    this._adjustHeader()
  }

  render() {
    const classes = this.context;
    let { length, date, events, accessors, localizer } = this.props
    let { messages } = localizer

    let start = dates.startOf(date, 'month');
    let end = dates.endOf(date, 'month');
    // let end = dates.add(date, length, 'day')

    let range = dates.range(start, end, 'day')

    events = events.filter(event => inRange(event, start, end, accessors))

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

    return (
      <div className={classes.agendaView}>
        {events.length !== 0 ? (
          <React.Fragment>
            <table ref={this.headerRef} className={classes.agendaTable}>
              <thead>
                <tr>
                  <th className={classes.header} ref={this.dateColRef}>
                    {messages.date}
                  </th>
                  {null /* 
                  <th className={classes.header} ref={this.timeColRef}>
                    {messages.time}
                  </th>
                  */ }
                  <th className={classes.header}>{messages.event}</th>
                </tr>
              </thead>
            </table>
            <div className={classes.agendaContent} ref={this.contentRef}>
              <table className={classes.agendaTable}>
                <tbody ref={this.tbodyRef}>
                  {range.map((day, idx) => this.renderDay(day, events, idx))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ) : (
          <span className={classes.agendaEmpty}>{messages.noEventsInRange}</span>
        )}
      </div>
    )
  }

  renderDay = (day, events, dayKey) => {
    let {
      selected,
      getters,
      accessors,
      localizer,
      components: { event: Event, date: AgendaDate },
    } = this.props

    events = events.filter(e =>
      inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors)
    )
    const classes = this.context;

    return events.map((event, idx) => {
      let title = accessors.title(event)
      let end = accessors.end(event)
      let start = accessors.start(event)

      const userProps = getters.eventProp(
        event,
        start,
        end,
        isSelected(event, selected)
      )

      let dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat')
      let first =
        idx === 0 ? (
          <td rowSpan={events.length} className={classes.agendaDateCell}>
            {AgendaDate ? (
              <AgendaDate day={day} label={dateLabel} />
            ) : (
              dateLabel
            )}
          </td>
        ) : (
          false
        )

      return (
        <tr
          key={dayKey + '_' + idx}
          className={userProps.className}
          style={userProps.style}
        >
          {first}
          {null /*
          <td className={classes.agendaTimeCell}>
            {this.timeRangeLabel(day, event)}
          </td> */ }
          <td className={classes.agendaEventCell}>
            {Event ? <Event event={event} title={title} isAgenda={true} /> : title}
          </td>
        </tr>
      )
    }, [])
  }

  timeRangeLabel = (day, event) => {
    let { accessors, localizer, components } = this.props

    let labelClass = '',
      TimeComponent = components.time,
      label = localizer.messages.allDay

    let end = accessors.end(event)
    let start = accessors.start(event)

    if (!accessors.allDay(event)) {
      if (dates.eq(start, end)) {
        label = localizer.format(start, 'agendaTimeFormat')
      } else if (dates.eq(start, end, 'day')) {
        label = localizer.format({ start, end }, 'agendaTimeRangeFormat')
      } else if (dates.eq(day, start, 'day')) {
        label = localizer.format(start, 'agendaTimeFormat')
      } else if (dates.eq(day, end, 'day')) {
        label = localizer.format(end, 'agendaTimeFormat')
      }
    }

	const classes = this.context;
    if (dates.gt(day, start, 'day')) labelClass = clsx(labelClass, classes.continuesPrior);
    if (dates.lt(day, end, 'day')) labelClass = clsx(labelClass, classes.continuesAfter);

    return (
      <span className={labelClass}>
        {TimeComponent ? (
          <TimeComponent event={event} day={day} label={label} />
        ) : (
          label
        )}
      </span>
    )
  }

  _adjustHeader = () => {
    if (!this.tbodyRef.current) return

    let header = this.headerRef.current
    let firstRow = this.tbodyRef.current.firstChild

    if (!firstRow) return

    let isOverflowing =
      this.contentRef.current.scrollHeight >
      this.contentRef.current.clientHeight
    let widths = this._widths || []

    this._widths = [
      getWidth(firstRow.children[0]),
      // getWidth(firstRow.children[1]),
    ]

    if (widths[0] !== this._widths[0]) { // || widths[1] !== this._widths[1]) {
      this.dateColRef.current.style.width = this._widths[0] + 'px'
      // this.timeColRef.current.style.width = this._widths[1] + 'px'
    }

    const classes  = this.context;
    if (isOverflowing) {
      String(classes.headerOverflowing).split(/\s*/g).filter(v => v).forEach(v => addClass(header, v))
      header.style.marginRight = scrollbarSize() + 'px'
    } else {
      String(classes.headerOverflowing).split(/\s*/g).filter(v => v).forEach(v => removeClass(header, v))
    }
  }
}

Agenda.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number.isRequired,

  selected: PropTypes.object,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired,
}

Agenda.defaultProps = {
  length: 30,
}

Agenda.range = (start, { length = Agenda.defaultProps.length }) => {
  let end = dates.add(start, length, 'day')
  return { start, end }
}

Agenda.navigate = (date, action, { length = Agenda.defaultProps.length }) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(dates.startOf(date, 'month'), -1, 'month');

    case navigate.NEXT:
      return dates.add(dates.startOf(date, 'month'), 1, 'month');

    default:
      return date
  }
}

Agenda.title = (start, { length = Agenda.defaultProps.length, localizer }) => {
  let month = dates.startOf(start, 'month');
  // let end = dates.add(start, length, 'day')
  return localizer.format(month, 'agendaHeaderFormat')
}

export default Agenda
