import React, { useState, useEffect, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { VerticalTimelineContext } from './context';
import BoxProps from './props';

/* const useVerticalTimelineClasses = () => useEffect(() => {
    let node = document.createElement('link');
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.href = 'public/vertical-timeline.css';
    document.head.appendChild(node);
    return () => {
        node.remove();
    }  
}, []) */

const BoxBlock = ({ classes, content, layout = '2-columns' }: BoxProps, ref: React.Ref<any>) => {
    // useVerticalTimelineClasses();

    return <VerticalTimelineContext.Provider value={{ classes }}>
        <VerticalTimeline className={classes.verticalTimeline} layout={layout}>
            {getChildren(content)}
        </VerticalTimeline>
    </VerticalTimelineContext.Provider>
};

export default ThemeLoader(
	StyleLoader(
		BoxBlock,
		makeStyles(
			theme => ({
                verticalTimeline: {
                    '@global': {
                        '@import': 'url(public/vertical-timeline.css)'
                    }
                },
                verticalTimelineElement: {},
                verticalTimelineIcon: {},
                verticalTimelineText: {},
                verticalTimelineDate: {}
			}),
			{ name: 'VerticalTimeline' }
		)
	)
);

