import { loadThemeModule } from '@sightworks/theme';
import { Registry, BlockPropsBase } from '@sightworks/block';
import { ModulePackageJson } from '@sightworks/module';

export default async function preload(props: BlockPropsBase): Promise<ModulePackageJson[]> {
	if (!props) return [];

	const p: ModulePackageJson[] = [];
	if (props.useStyles) {
		// console.log("Load: %o", props.useStyles);
		try {
			await loadThemeModule(props.useStyles);
			p.push(props.useStyles);
		} catch (e) { console.log(e); }
	}
	//if (props.useTheme) {
	//	// console.log("Load: %o", props.useTheme);
	//	await loadThemeModule(props.useTheme);
	//	p.push(props.useTheme);
	//}

	try {
		const children = Registry.getChildren(props);
		for (const child of children) {
			p.push(...await preload(child));
		}
		return p;
	} catch (e) {
		console.log({ props, e });
		throw e;
	}
}

export function enumerateModules(props: BlockPropsBase): ModulePackageJson[] {
	if (!props) return [];

	const p: ModulePackageJson[] = [];
	if (props.useStyles) {
		p.push(props.useStyles);
	}

	const children = Registry.getChildren(props);
	for (const child of children) {
		p.push(...enumerateModules(child));
	}

	return p;
}
