import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '../../components/Typography';
import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { Context } from '../../utils/query';
import { Context as GalleryContext } from '../../special/gallery-container/context';
import CardProps from './props';

// import Fade from '@material-ui/core/Fade';

type TitleTextProps = {
	content: string;
	decoration: string;
}
const TitleText = ({ content, decoration }: TitleTextProps) => {
	if (typeof content != 'string') return content;
	let p = content.indexOf(',');
	if (p == -1) return <>{content}</>;
	let left = content.substring(0, p);
	let right = content.substring(p);
	return (
		<>
			{left}
			<span className={decoration}>{right}</span>
		</>
	);
};

const getScaled = (url: string): string => {
	if (url.startsWith('/')) {
		if (url.endsWith('.png')) return `/t/width=500/matte=none/scale/f${url}/scaled.png`;
		return `/t/width=500/matte=none/scale/f${url}/scaled.jpg`;
	}
	return url;
}

let InnerCardBlock = (props: CardProps, ref: React.Ref<any>) => {
	const cx = useContext(Context);
	const galleryContext = useContext(GalleryContext);
	const [galleryIndex, setGalleryIndex] = useState(null);
	let openItem: React.MouseEventHandler = void 0;

	let href: string = null;
	if (props.link && 'href' in props.link) {
		href = props.link.href;
		if (props.link.preserveQuery) {
			href = cx.extend(href);
		}
	}

	let linkButtonHref: string = null;
	if (props.linkButton && 'href' in props.linkButton) {
		linkButtonHref = props.linkButton.href;
		if (props.linkButton.preserveQuery) {
			linkButtonHref = cx.extend(linkButtonHref);
		}
	}

	useEffect(() => {
		if (props.link && 'embed' in props.link && props.link.embed) {
			if (galleryContext) {
				let o = {
					src: props.image,
					title: props.title,
					summary: props.content,
					embeddedObject: props.link.embed,
				};
				galleryContext.register(
					o,
					setGalleryIndex
				);
			}
		}
	}, [props.link]);

	if (galleryIndex !== null) {
		openItem = e => {
			e.preventDefault();
			galleryContext.toggle(galleryIndex);
		};
	}

	const [imageOrientation, setImageOrientation] = useState<'landscape' | 'portrait'>(null);
	const [containedImageMargin, setContainedImageMargin] = useState<number>(0);

	const aspectRatioMap = {
		'4:3': '75%',
		'16:9': '56.25%',
		square: '100%',
	};

	useEffect(() => {
		if (!props.image) return;

		const img = new Image();
		img.addEventListener('load', function () {
			const ratio = aspectRatioMap[props.mediaAspectRatio];
			const ratioVal = Number(ratio.substring(0, ratio.length - 1));
			if (this.width > this.height) {
				setImageOrientation('landscape');
				const heightDiff = Math.abs((this.height / this.width) * 100 - ratioVal) / 2;
				// if (heightDiff < 5) return setContainedImageMargin(0);
				return setContainedImageMargin(heightDiff);
			}
			setImageOrientation('portrait');
			const widthDiff = Math.abs((this.width / this.height) * 100 - ratioVal) / 2;
			// if (widthDiff < 5) return setContainedImageMargin(0);
			return setContainedImageMargin(widthDiff);
		}, false);
		img.src = getScaled(props.image);
	}, []);

	if (props.isLoading) {
		return (
			<Card
				className={clsx(props.classes.root, props.classes.loader)}
				ref={ref}
				style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] } as CSSProperties}
			>
				<CardActionArea className={props.classes.actionArea}>
					<Skeleton className={props.classes.media} variant="rect" />
					<CardContent className={props.classes.cardContent}>
						{props.caption && <Skeleton className={props.classes.caption} />}
						<Skeleton className={props.classes.title} />
						<Skeleton className={props.classes.content} />
						{props.content2 && <Skeleton className={props.classes.content2} />}
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	const initialedTitle = () => {
		const split = props.contentPrepend.title.split(' ');
		const first = split[0][0].toUpperCase();
		const last = split[1][0].toUpperCase();
		return `${first}${last}`;
	};

	const makeActionArea = (props: CardProps) => (
		<>
			{props.stripe && (
				<Typography
					variant={props.stripeVariant}
					color={props.stripeColor}
					component="div"
					className={props.classes.cardStripe}
				>
					{props.stripe}
				</Typography>
			)}
			{(props.icon || props.image) && (
				<div className={props.classes.mediaWrap}>
					<CardMedia
						className={clsx(props.classes.media, {
							[props.classes.hidden]:
								props.imageFit === 'contain' && props.orientation !== 'horizontal',
						})}
						image={
							props.image ? getScaled(props.image) : undefined
						}
						title={props.altText}
					>
						{props.icon && <Icon className={props.classes.icon}>{props.icon}</Icon>}
					</CardMedia>
				</div>
			)}
			{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
				<div className={props.classes.blurContainer}>
					<CardMedia
						className={props.classes.blur}
						image={
							props.image ? getScaled(props.image) : undefined
						}
						title={props.altText}
					/>
				</div>
			)}
			{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
				<div className={props.classes.containedImageContainer}>
					<img
						className={
							imageOrientation === 'portrait'
								? props.classes.portraitImage
								: props.classes.landscapeImage
						}
						style={{ marginTop: imageOrientation === 'landscape' ? `${containedImageMargin}%` : 0 }}
						src={
							props.image ? getScaled(props.image) : undefined
						}
						alt={props.altText}
					/>
				</div>
			)}
		</>
	);

	let fallback = null;

	if (props.contentPrepend.title) {
		fallback = props.contentPrepend.image ? null : props.contentPrepend.title[0].toUpperCase();
	}

	if (props.linkButton)
		return (
			<Card
				className={clsx(props.classes.root, { [props.classes.withIcon]: props.icon })}
				ref={ref}
				style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] } as CSSProperties}
			>
				<div className={props.classes.actionArea}>
					{(props.link && href) ?
						(
							<CardActionArea
								href={props.link ? href : void 0}
								className={props.classes.linkButtonActionArea}
								target={props.link && 'href' in props.link && props.link.openInNewWindow ? '_blank' : void 0}
								onClick={openItem}
							>
								{makeActionArea(props)}
							</CardActionArea>
						) : (
							<div className={props.classes.linkButtonActionArea}>
								{makeActionArea(props)}
							</div>
						)
					}
					<CardContent className={props.classes.cardContent}>
						{props.caption && (
							(props.link && href) ? (
								<ButtonBase
									href={props.link ? href : void 0}
									className={props.classes.captionButton}
									target={props.link && 'href' in props.link && props.link.openInNewWindow ? '_blank' : void 0}
								>
									<Typography
										variant={props.captionVariant}
										className={props.classes.caption}
										color={props.captionColor}
									>
										{props.caption}
									</Typography>
								</ButtonBase>
							) : (
									<Typography
										variant={props.captionVariant}
										className={props.classes.caption}
										color={props.captionColor}
									>
										{props.caption}
									</Typography>
								)
						)}
						{props.title && <Typography
							gutterBottom
							variant={props.titleVariant}
							color={props.titleColor}
							component="h2"
							className={props.classes.title}
						>
							<TitleText content={props.title} decoration={props.classes.decoration} />
						</Typography>}
						{(props.contentPrepend.image || props.contentPrepend.title || props.content) && <div className={props.classes.contentContainer}>
							{(props.contentPrepend.image || props.contentPrepend.title) && (
								<Avatar
									src={
										props.contentPrepend.image ? getScaled(props.contentPrepend.image) : null
									}
									variant="rounded"
									className={props.classes.avatarRoot}
								>
									{fallback}
								</Avatar>
							)}
							<Typography
								variant={props.contentVariant}
								color={props.contentColor}
								component="p"
								className={props.classes.content}
							>
								{props.content}
							</Typography>
						</div>}
						{props.content2 && (
							<Typography
								variant={props.content2Variant}
								color={props.content2Color}
								component="p"
								className={props.classes.content2}
							>
								{props.content2}
							</Typography>
						)}
						{linkButtonHref && (
							<ButtonBase
								href={linkButtonHref}
								className={props.classes.linkButtonWrapper}
								target={('href' in props.linkButton && props.linkButton.openInNewWindow) ? '_blank' : void 0}
							>
								<Typography
									className={props.classes.linkButton}
									variant={props.linkButtonVariant}
									color={props.linkButtonColor}
								>
									{props.linkButtonText}
								</Typography>
							</ButtonBase>
						)}
					</CardContent>
				</div>
			</Card>
		);

	const CAA = ((props.link && 'href' in props.link) || openItem) ? CardActionArea : (props: React.PropsWithChildren<{ href?: string, className?: string, target?: string, onClick?: React.MouseEventHandler }>) => (<div className={props.className}>{props.children}</div>);

	return (
		<Card
			className={clsx(props.classes.root, { [props.classes.withIcon]: props.icon })}
			ref={ref}
			style={{ '--aspect-ratio': aspectRatioMap[props.mediaAspectRatio] || aspectRatioMap['16:9'] } as CSSProperties}
		>
			<CAA
				href={props.link ? href : void 0}
				className={props.classes.actionArea}
				target={props.link && 'href' in props.link && props.link.openInNewWindow ? '_blank' : void 0}
				onClick={openItem}
			>
				{props.stripe && (
					<Typography
						variant={props.stripeVariant}
						color={props.stripeColor}
						component="div"
						className={props.classes.cardStripe}
					>
						{props.stripe}
					</Typography>
				)}
				{(props.image || props.icon) && (
					<div className={props.classes.mediaWrap}>
						<CardMedia
							className={clsx(props.classes.media, {
								[props.classes.hidden]: props.imageFit === 'contain' && props.orientation !== 'horizontal',
							})}
							image={
								props.image ? getScaled(props.image) : undefined
							}
							title={props.altText}
						>
							{props.icon && <Icon className={props.classes.icon}>{props.icon}</Icon>}
						</CardMedia>
					</div>
				)}
				{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
					<div className={props.classes.blurContainer}>
						<CardMedia
							className={props.classes.blur}
							image={
								props.image ? getScaled(props.image) : undefined
							}
							title={props.altText}
						/>
					</div>
				)}
				{props.image && (props.imageFit === 'contain' && props.orientation !== 'horizontal') && (
					<div className={props.classes.containedImageContainer}>
						<img
							className={
								imageOrientation === 'portrait' ? props.classes.portraitImage : props.classes.landscapeImage
							}
							style={{ marginTop: imageOrientation === 'landscape' ? `${containedImageMargin}%` : 0 }}
							src={
								props.image ? getScaled(props.image) : undefined
							}
							alt={props.altText}
						/>
					</div>
				)}
				{(props.caption || props.title || props.contentPrepend.image || props.contentPrepend.title || props.content || props.content2) && <CardContent className={props.classes.cardContent}>
					{props.caption && (
						<Typography
							gutterBottom
							variant={props.captionVariant}
							color={props.captionColor}
							component="h6"
							className={props.classes.caption}
						>
							{props.caption}
						</Typography>
					)}
					{props.title && (
						<Typography
							gutterBottom
							variant={props.titleVariant}
							color={props.titleColor}
							component="h2"
							className={props.classes.title}
						>
							<TitleText content={props.title} decoration={props.classes.decoration} />
						</Typography>
					)}
					{(props.contentPrepend.image || props.contentPrepend.title || props.content) && (
						<div className={props.classes.contentContainer}>
							{(props.contentPrepend.image || props.contentPrepend.title) && (
								<Avatar
									src={
										props.contentPrepend.image ? getScaled(props.contentPrepend.image) : null
									}
									variant="rounded"
									className={props.classes.avatarRoot}
								>
									{fallback}
								</Avatar>
							)}
							<Typography
								variant={props.contentVariant}
								color={props.contentColor}
								component="p"
								className={props.classes.content}
							>
								{props.content}
							</Typography>
						</div>
					)}
					{props.content2 && (
						<Typography
							variant={props.content2Variant}
							color={props.content2Color}
							component="p"
							className={props.classes.content2}
						>
							{props.content2}
						</Typography>
					)}
				</CardContent>}
			</CAA>
		</Card>
	);
};

InnerCardBlock = React.forwardRef(InnerCardBlock);

const stripe: Record<string, CSSProperties> = {
	cardStripe: {
		top: 18,
		right: -22,
		position: 'absolute',
		transform: 'rotate(45deg)',
		width: 100,
		textAlign: 'center',
		transformOrigin: '50% 50%',
	},
};

const VerticalStyles = makeStyles(
	theme => ({
		...stripe,
		root: {
			'&$withIcon:hover $media:before, &:hover $media $icon': { opacity: 1 },
		},
		actionArea: {},
		media: {
			paddingTop: 'var(--aspect-ratio)',
			position: 'relative',
			'&:before': {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				background: 'rgba(0, 0, 0, 0.6)',
				opacity: 0,
				transition: theme.transitions.create('opacity'),
				content: '""',
			},
			'& $icon': { opacity: 0, transition: theme.transitions.create('opacity') },
		},
		icon: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			fontSize: '7rem',
			color: '#fff',
			textShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 1px',
		},
		withIcon: {},
		mediaWrap: {},
		cardContent: {},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			paddingTop: 'var(--aspect-ratio)',
			backgroundSize: 'cover',
			position: 'absolute',
			width: '100%',
			top: 0,
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
			justifyContent: 'center',
			display: 'flex',
		},
		hidden: {
			opacity: 0,
		},
		landscapeImage: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		},
		portraitImage: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		image: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
	}),
	{ name: 'SwVerticalCard' }
);

const StackedStyles = makeStyles(
	theme => ({
		...stripe,
		root: {
			width: '100%',
		},
		withIcon: {},
		actionArea: {
			position: 'relative',
		},
		mediaWrap: {},
		media: {
			paddingTop: 'var(--aspect-ratio)',
		},
		cardContent: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
		},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			paddingTop: 'var(--aspect-ratio)',
			backgroundSize: 'cover',
			position: 'absolute',
			width: '100%',
			top: 0,
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '100%',
			paddingTop: 'var(--aspect-ratio)',
			overflow: 'hidden',
			justifyContent: 'center',
			display: 'flex',
		},
		hidden: {
			opacity: 0,
		},
		image: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		linkButton: {},
		linkButtonWrapper: {},
		linkButtonActionArea: {
			position: 'relative',
		},
	}),
	{ name: 'SwStackedCard' }
);

const HorizontalStyles = makeStyles(
	theme => ({
		...stripe,
		root: {
			'--image-width': '150px',
			minHeight: 'var(--image-width)',
		},
		withIcon: {},
		actionArea: {
			paddingLeft: 'var(--image-width)',
			position: 'relative',
			display: 'flex',
		},
		linkButtonActionArea: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: 'var(--image-width)',
			bottom: 0,
		},
		linkButton: {},
		linkButtonWrapper: {},
		mediaWrap: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			width: 'var(--image-width)',
		},
		media: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			paddingTop: '100%',
			objectFit: 'cover',
			objectPosition: 'top center',
		},
		icon: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			fontSize: '2rem',
			transform: 'translate(-50%, -50%)',
			color: '#fff',
		},
		cardContent: {
			// marginTop: 'auto',
			// marginBottom: 'auto',
			width: '100%',
		},
		caption: {},
		title: {},
		content: {},
		content2: {},
		blur: {
			backgroundSize: 'cover',
			height: '100%',
			opacity: 0.7,
			filter: 'blur(30px)',
		},
		blurContainer: {
			top: 0,
			height: '100%',
			position: 'absolute',
			width: '30%',
			overflow: 'hidden',
		},
		containedImageContainer: {
			position: 'absolute',
			top: 0,
			width: '30%',
			height: '100%',
			display: 'flex',
		},
		imageContainerTest: {
			height: '100%',
			width: '100%',
		},
		hidden: {
			opacity: 0,
		},
		landscapeImage: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		},
		portraitImage: {
			height: '100%',
			width: 'auto',
			position: 'absolute',
			top: 0,
		},
		image: {
			height: 'auto',
			width: '100%',
			position: 'absolute',
			top: 0,
		},
	}),
	{ name: 'SwHorizontalCard' }
);

let HorizontalCardBlock = (props: CardProps, ref: React.Ref<any>) => <InnerCardBlock {...props} ref={ref} />;
let VerticalCardBlock = (props: CardProps, ref: React.Ref<any>) => <InnerCardBlock {...props} ref={ref} />;
let StackedCardBlock = (props: CardProps, ref: React.Ref<any>) => <InnerCardBlock {...props} ref={ref} />;

HorizontalCardBlock = ThemeLoader(StyleLoader(HorizontalCardBlock, HorizontalStyles));
VerticalCardBlock = ThemeLoader(StyleLoader(VerticalCardBlock, VerticalStyles));
StackedCardBlock = ThemeLoader(StyleLoader(StackedCardBlock, StackedStyles));

let Cards = {
	horizontal: HorizontalCardBlock,
	vertical: VerticalCardBlock,
	stacked: StackedCardBlock,
};

const RootCardBlock = (props: CardProps, ref: React.Ref<any>) => {
	const [prevState, setPrevState] = useState(props);
	const updateState = useCallback(() => {
		setPrevState(props);
	}, [props]);
	const theme = useTheme();
	const loadingChanged = prevState.isLoading != props.isLoading;
	const [transitionState, setTransitionState] = useState('none');
	useEffect(() => {
		const ignore = false;
		if (loadingChanged) {
			switch (transitionState) {
				case 'none':
					setTransitionState('init');
					break;

				case 'init':
					setTransitionState('start');
					break;

				case 'start':
					setTimeout(() => {
						setTransitionState('end');
					}, 300);
					break;

				case 'end':
					setPrevState(props);
					break;

				default:
					setTransitionState('init');
			}
		} else if (transitionState == 'end') {
			setTransitionState('none');
		}
	}, [props, loadingChanged, transitionState]);

	let cardLoading;
	let cardDone;
	if (props.isLoading) cardLoading = props;
	else cardDone = props;
	if (prevState.isLoading && !cardLoading) cardLoading = prevState;
	else if (!prevState.isLoading && !cardDone) cardDone = prevState;

	return (
		<div
			className={clsx(props.classes.transitionContainer, {
				[props.classes.loading]: transitionState == 'none' ? props.isLoading : prevState.isLoading,
				[props.classes.transitionInit]: transitionState == 'init' || transitionState == 'start',
				[props.classes.transitionRun]: transitionState == 'start',
				[props.classes.transitionDone]: transitionState == 'end',
			})}
		>
			{cardLoading && (
				<div className={clsx(props.classes.transitionItem, props.classes.transitionLoader)}>
					{React.createElement(Cards[cardLoading.orientation || 'vertical'], cardLoading)}
				</div>
			)}
			{cardDone && (
				<div className={clsx(props.classes.transitionItem, props.classes.transitionTarget)}>
					{React.createElement(Cards[cardDone.orientation || 'vertical'], {
						...cardDone,
						ref,
					})}
				</div>
			)}
		</div>
	);
};

const RootCardStyles = makeStyles(
	theme => ({
		stripe: {},
		root: {},
		withIcon: {},
		actionArea: {},
		media: {},
		cardContent: {
			'& .MuiTypography-subtitle1': {
				lineHeight: 1,
			},
		},
		caption: {},
		title: {},
		content: {},
		avatarRoot: {
			marginRight: theme.spacing(1),
			fontSize: theme.typography.subtitle1.fontSize,
			fontWeight: 'bold',
		},
		contentContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		content2: {},
		transitionContainer: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: 'auto',
			gridTemplateRows: 'auto',
		},
		transitionItem: {
			gridColumn: 1,
			gridRow: 1,
			position: 'relative',
			'& + $transitionItem': {
				zIndex: 1,
			},
			opacity: 0,
			display: 'flex',
			'& > $root': {
				flex: '1 1 auto',
				display: 'flex',
				'& > $actionArea': {
					flex: '1 1 auto',
				},
			},
		},
		transitionLoader: {},
		transitionTarget: {
			opacity: 1,
		},
		loading: {
			'& > $transitionLoader': { opacity: 1 },
			'& > $transitionTarget': { opacity: 0 },
		},
		transitionInit: {
			'& > $transitionItem': {
				transition: 'opacity .3s ease',
			},
		},
		transitionRun: {
			'& > $transitionLoader': {
				opacity: 1,
			},
			'& > $transitionTarget': {
				opacity: 0,
			},
			'&$loading': {
				'& > $transitionLoader': {
					opacity: 0,
				},
				'& > $transitionTarget': {
					opacity: 1,
				},
			},
		},
		transitionDone: {
			'& > $transitionLoader': {
				opacity: 1,
			},
			'& > $transitionTarget': {
				opacity: 0,
			},
			'&$loading': {
				'& > $transitionLoader': {
					opacity: 0,
				},
				'& > $transitionTarget': {
					opacity: 1,
				},
			},
		},
		linkButton: {},
		linkButtonWrapper: {},
		linkButtonActionArea: {},
		mediaWrap: {},
		icon: {},
		decoration: {},
	}),
	{ name: 'SwCard' }
);

export default ThemeLoader(StyleLoader(RootCardBlock, RootCardStyles));
