import Block from './block';
import BoxProps from './props';
import { BlockType, flattenChildren } from '../..';
import { Context as PaginatorContext } from '../paginator-container/context';
import { useContext, useEffect } from 'react';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;
let appendNode: typeof import('../../utils/parent').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
	appendNode = require('../../utils/parent').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const BoxBlock: BlockType = {
	component: Block,
	getChildren(props: BoxProps) {
		try {
			let context = useContext(PaginatorContext);
			let children = flattenChildren(props.content);
			useEffect(() => {
				context?.setItemCount(children.length)
			}, [context?.setItemCount, children.length]);
			let cbs = context ? children.slice(context.page * context.itemsPerPage, (context.page + 1) * context.itemsPerPage) : children;
			return cbs;
		} catch (e) {
			// Oh, we're not in a React tree.
			return flattenChildren(props.content); 
		}
	},
	description,
	section: 'Special', // A box is really more of just a basic HTML element - it's almost always output as <div>.
	info: {
		// null | array<string>: what container must this be a direct child of; null = anything.
		// If requiresParent is the empty array, it can never be selected as a child element.
		requiresParent: null,

		// null | array<string>: what container must be somewhere above this; null = anything
		// If requiresAncestor is the empty array, this component can never appear in the tree.
		// Anything specified in requiresParent must also be specified here.
		requiresAncestor: ['paginator-container'],

		// null | array<string>: what can this element have for children; null = anything; empty array marks this as an empty component without children.
		permittedChildren: null,

		// array<string>: where can this element not appear
		forbiddenAncestor: [],

		// array<string>: what elements cannot appear inside of this one
		forbiddenDescendant: []
	},
	getProps,
	appendNode,
	cacheRequiresRecord: () => false
};
export default BoxBlock;

